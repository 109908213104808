import React, { Component } from 'react' // Import
import Container from 'react-bootstrap/Container'
import Header, { Item, Float, Foot, Slider } from '../components/main'
import { Helm } from '../components/header'
import { Form, Row, Col, Alert } from 'react-bootstrap'
import { cap, pw } from '../params'
import '../style/style.css'
import '../style/sty.scss'
import '../style/gold3.css'
import burung from '../assets/img/nasta/burung.svg'
import AOS from 'aos';
import { gambar } from '../params'
import post from '../params/post'
import { Timer } from '../components/timer'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import logo from '../assets/img/logo.ico'
import "aos/dist/aos.css";
import 'bootstrap/dist/css/bootstrap.min.css';

let cmain = '#F6C441'
let black = 'rgb(38,38,38)'

let id = 'herlina-enggar'
let id_temp = 'reni-shidqi'
let inisial_co = 'Enggar'
let inisial_ce = 'Herlina'

let lengkap_co = (<>Romualdus Enggar Pramanto Wibowo</>)
let lengkap_ce = (<>Clara Herlina</>)

let bapak_co = 'B. Hermen Effendhi Pranowo '
let ibu_co = 'Benedicta Dewi Tjahyaningsih'
let bapak_ce = "Toni Munandi (Lim Kian Ming) "
let ibu_ce = "Melania Wong Lan Chen"

let ig_co = "romualdus_enggar"
let ig_ce = "clara_herlina"

let foto_ce = pw(id, "ce.jpg")
let foto_co = pw(id, "co.jpg")
let waktunikah = "01/02/2021"

let modal = pw(id_temp, "modal.jpg")
let openlogo = pw(id, "logo.png")
let logoig = pw("asset", "logoig-green.svg")
let gmaps = "https://goo.gl/maps/s2pzfcJxGc2uYvgPA"
let gcalendar = 'https://calendar.google.com/event?action=TEMPLATE&tmeid=NzQ4aThzYW9uczRkc2o1MzQ0ZjkzNnE0cDEgYXJpZWZjNzJAbQ&tmsrc=ariefc72%40gmail.com'
let gmaps1 = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.093133299288!2d106.8281107147699!3d-6.381979395382038!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ebf78d019c33%3A0xb1344253f27a9dbf!2sSavero%20Hotel%20Depok!5e0!3m2!1sid!2sid!4v1607449466269!5m2!1sid!2sid"
let slide = [
    "1 Prewed jawa.jpg",
    "2 Sangjit Hugs.jpg",
    "3 Prewed elegan.jpg",
    "4 Cantik Ganteng.jpg",
    "5 Prague.jpg",
    "6 Zugspitze.jpg",
    "7 Munchen.jpg",
    "8 Dance with me.jpg",
    "9 Cium Kening.jpg",
    "10 Adat Jawa.jpg",
    "11 Eibsee.jpg",
    "12 Colloseum.jpg",
    "13 Dinner.jpg",
    "14 Roma.jpg",
    "15 Foto prewed gaun.jpg",
    "16 Best Friend.jpg",
    "17 Lourdes.jpg",
    "18 Photo collage.jpg",
    "19 souvenir.jpg",
    "20 Family.jpg",
]

export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef()
        this.myRef = React.createRef()
        this.nama = React.createRef()
        this.alamat = React.createRef()
        this.pesan = React.createRef()

        this.state = {
            acara: [],
            days: '00',
            minutes: '00',
            hours: '00',
            seconds: '00',
            hide: true,
            hadir: true,
            err: [],
            submitted: '',
            sesi: 2
        }
    }

    componentDidMount() {
        AOS.init({
            // initialise with other settings
            duration: 2000
        });

    }

    play = () => {
        AOS.refresh()
        var snd = new Audio(pw(id, "music.mp3"));
        snd.type = 'audio/mp3';
        snd.play();
        this.setState({ hide: false })
        setTimeout(() => {
            var elmnt = document.getElementById('top');
            elmnt.scrollIntoView();
        }, 1000)
    }

    useQuery = () => {
        return new URLSearchParams(this.props.location.search);
    }
    handleSubmit = async () => {
        let err = []
        let local = localStorage.getItem('pesan')
        if (this.nama.current.value == "") {
            err.push('Nama tidak Boleh Kosong')
        }
        if (this.pesan.current.value == "") {
            err.push('Pesan tidak Boleh Kosong')
        }
        if (err.length == 0) {
            confirmAlert({
                message: local ? `Kami mendeteksi bahwa anda telah mengirimkan pesan \" ${local} \", apakah anda ingin mengirim pesan lagi?` : 'Yakin untuk Mengirim Pesan?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            let send = await post(
                                ` dari: "${encodeURI(this.nama.current.value)}", hadir: "", jumlahhadir: "", kepada: "${id}", pesan:"${encodeURI(this.pesan.current.value)}",alamat: ""`
                            )
                            if (send.status == 200) {
                                this.setState({ submitted: true })
                                localStorage.setItem('pesan', this.pesan.current.value)
                                this.nama.current.value = ''
                                this.pesan.current.value = ''
                            } else {
                                err.push('Koneksi Gagal')
                            }
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => { }
                    }
                ]
            });
        } else {
            this.setState({ err: err, submitted: false })
        }
    }
    render() {
        let { hadir, days, hours, minutes, seconds, hide, submitted, err, showgift } = this.state
        let slider = []
        slide.map(v => {
            slider.push(gambar(pw(id, v), 95, 'auto&func=fit&bg_img_fit=1&bg_opacity=0.75&w=800&h=520'))
        })
        let query = this.useQuery().get('u');
        query = query ? cap(query) : ''
        let lang = this.useQuery().get('lang');

        return (
            <>
                <Helm
                    title={`Undanganku - ${inisial_ce} & ${inisial_co}`}
                    desc="undangan digital berbasis website untuk berbagai kebutuhan acara"
                    logo={logo}
                    img={slide[0]}
                    url={`https://undanganku.me/${id}`}
                />

                <div id='gold5' style={{
                    backgroundColor: '#fdfcf4ff'
                }}>
                    {
                        this.useQuery().get('x') == "x" ? (<Float />) : false
                    }
                    <div className='w-100' style={{
                        overflow: 'hidden', maxWidth: '100vw',
                        backgroundColor: 'transparent'
                    }}>
                        <Container fluid id='g3-header' className='position-relative' style={{
                            backgroundColor: "#a30000", backgroundImage: "none"
                        }}>
                            <Col md={2} xs={5} className="position-absolute" style={{ left: '0px', top: "15px" }}>
                                <img src={pw(id, "corner1.jpg")} className="mirror img-fluid w-100" />
                            </Col>
                            <Col md={2} xs={5} className="position-absolute" style={{ right: '0px', top: "15px" }}>
                                <img src={pw(id, "corner1.jpg")} className=" img-fluid w-100" />
                            </Col>
                            <Col md={2} xs={5} className="position-absolute" style={{ left: '0px', bottom: "15px" }}>
                                <img src={pw(id, "corner1.jpg")} className=" flip180  img-fluid w-100" />
                            </Col>
                            <Col md={2} xs={5} className="position-absolute" style={{ right: '0px', bottom: "15px" }}>
                                <img src={pw(id, "corner1.jpg")} className=" flip180-m img-fluid w-100" />
                            </Col>

                            <Item>
                                <Col xs={12} md={4} className='m-2 m-md-0 '>
                                    <img className='img-fluid w-100 p-4'
                                        src={openlogo} data-aos="fade-left" />
                                </Col>
                            </Item>
                            <Item>
                                {
                                    <h2 className={`col-md-4 roboto-slab text-center pt-3 pt-sm-3`}
                                        style={{ marginTop: '0', color: '#F6C441' }}>
                                        {lang=="en"?"To":"Kepada Yth"} :<br /> {query ? query : ''} <br /></h2>
                                }</Item>
                            <Row className='justify-content-center'>
                                <div onClick={() => { this.play() }}

                                    className={`col-md-4 button btn roboto-slab text-center ${hide ? 'show' : 'hide'}`}
                                    style={{ marginTop: 0, color: '#F6C441', borderColor: '#F6C441' }}>
                                    Open Invitation
                            </div>
                            </Row>
                        </Container>


                        <div className={hide ? 'd-none' : 'd-block'}>
                            <div id="top" style={{ backgroundColor: 'transparent' }}>
                                <Container fluid style={{
                                    backgroundColor: '#fdfcf4',
                                    fontFamily: 'Rosemary', color: 'black', fontSize: '16px'
                                }} className="py-3" >
                                    <Item>
                                        <img src={pw(id, "top.svg")} className="col-md-8 img-fluid" />
                                    </Item>
                                    <Item>
                                        <div className="p-2 btn px-5 mb-3" onClick={()=>{
                                            window.location.href=lang=="en"?'./?lang=id':'./?lang=en'
                                        }}
                                        style={{borderRadius:'10px',border:`2px solid ${cmain}`}}>
                                            <Item>
                                            <img src={lang=="en"?"https://cdn3.iconfinder.com/data/icons/major-world-flags-1/512/indonesia_indonesian_national_country_flag-512.png":"https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/United-kingdom_flag_icon_round.svg/1024px-United-kingdom_flag_icon_round.svg.png"
                                            }
                                            style={{width:'35px',height:'35px'}}
                                            />
                                           <p className="px-2 mb-0">
                                           {lang=="en"?'Ganti Bahasa Indonesia':'Switch to English'}
                                           </p>
                                            </Item>
                                        </div>
                                    </Item>
                                    <Item>
                                        <p className="text-center col-md-8">

                                            {
                                                lang == "en" ? (<>
                                                    From the moment two souls saw each other for the first time at Universitas Indonesia in 2008, it was as if Life itself was looking to bring them together as they worked through the tasks as a coordinator and vice-coordinator of the Organisation of Catholic Students of Universitas Indonesia. Navigating this boat was daunting, yet it was through this trial that both hearts grew to accompany one another, a true friendship from which love was instilled.
<br /><br />These two hearts were purged through layers of knowledge and experiences along the way, the very way which was probably God’s design to be theirs. Even when both were separated as far as from sunset to sunrise between continents, the two hearts still found a way to see each other again. It was during the pilgrimage in May 2018 when the two were reunited; when the eyes saw, the feelings expressed, the emotions conveyed. Love has beautifully bloomed; feelings were indeed cherished along the past decade.
<br /><br />Nothing was easy. Time and space were still separating the two hearts, but neither far nor strong enough to cut what was bound by the Life. It was vividly clear that 2019 was the year to learn how to love mutually, how to live it and share each other’s stories with it amidst the constant flux of challenge.
<br /><br />And today, notwithstanding the constant separation of time and space, we have the faith to step towards the altar and say the solemn vow. We are readying ourselves for our new life together, in this very boat to take on what life has in store for us, its light as well as its shadows. We have the courage for we have some faith that this love contains so much more than words can convey.

</>) : (
                                                        <>
                                                            Berawal dari pertemuan dua insan yang hendak mencari ilmu
                                                            di FMIPA UI pada tahun 2008,
                                                            kesempatan menjadi koordinator dan wakil koordinator KMK FMIPA UI
                                                            seakan sengaja digariskan Sang Hidup untuk menyandingkan kami.
                                                            Tantangan membentuk KMK FMIPA sebagai bahtera bagi para anggota
                                                            dan berkat untuk sesama, mempererat dua hati menjadi sahabat sejati.
Seiring jalannya persahabatan, cinta pun perlahan menghampiri.<br /><br />
Matangnya ilmu dan derasnya pengalaman menjadi tempaan
bagi mantapnya hati di jalan yang hendak ditelusuri,
jalan untuk bersanding yang barangkali adalah kehendak-Nya.
Kali berikutnya terpisah pagi dan senja antar dua benua,
dua hati menemukan jalan untuk bertemu kembali.
Ziarah Mei 2018 menandai bersatunya kedua hati ini.
Dua insan bersua, perasaan terucap, emosi terlantun.
Nyatalah bahwa satu dekade yang terlampaui
telah mengikat rasa dan membangun cinta.<br /><br />
Ruang dan waktu tetap terbentang,
namun tak cukup jauh untuk memutus ikatan dari Sang Hidup.
Jalannya memang tak mudah, namun tak dapat dipungkiri
2019 menjadi tahun bagi kami untuk belajar saling mencinta.
Berbagi cita, cinta dan cerita,
walau hidup tidak selalu mulus dan penuh dengan deru tantangan.<br /><br />
Dan kini, meski tetap terpisah ruang dan waktu,
kami yakin untuk berjalan melangkah menuju altar dan mengucap janji setia.
Bersiap menjalani kehidupan baru, bersama mengarungi bahtera hidup,
berjuang bersama dalam suka dan duka kehidupan,
karena kami percaya, Cinta ini jauh lebih kaya dari sekedar suratan kata
                                                    </>
                                                    )
                                            }
                                        </p>
                                    </Item>
                                    <h1 className="w-100 text-center" style={{ fontSize: '36px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                        {inisial_ce} & {inisial_co}
                                    </h1>
                                    <p className='text-center p-2 px-4 ' style={{ color: 'black' }}>
                                        #foREverinCHrist
                                        </p>
                                    <Item>
                                        <img src={pw(id, "bottom.svg")} className="col-md-8 img-fluid" />
                                    </Item>

                                </Container>
                                <Container className="dinny px-3 pt-5 ">
                                    <Item>

                                        <p className="fs16 text-center cblack px-3">
                                            {
                                                lang == "en" ? (<>
                                                    All thanks and praise to the Lord, God Almighty, who has bound us together and bestowed his eternal love upon us both.
                                                </>) : (<>Puji dan syukur kehadirat Allah,
                                                    Tuhan Yang Maha Esa,
                                                    yang telah mempertemukan dan menganugerahkan
                                                    kasih karuniaNya kepada kami berdua:</>)
                                            }
                                        </p>
                                    </Item>
                                    <Item>
                                        <Col xs={6} sm={2}>
                                            <img src={burung} data-aos="zoom-in" data-aos-duration="1000" className='img-fluid w-100' />
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p className='text-center p-2 px-4 ' style={{ color: cmain }}>
                                            The Intimate Wedding of <br /> {inisial_ce} & {inisial_co}
                                        </p>

                                    </Item>
                                </Container>
                                <Container id='sectiongold55' className="py-5 dinny" >

                                    <Item>
                                        <div className=' col-xs-12 col-lg-6' data-aos="fade-left" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', color: cmain, fontFamily: "'Marck Script', cursive" }}>
                                                        {inisial_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_ce, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center"
                                                        style={{ fontSize: '32px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {lengkap_ce}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>{lang == "en" ? (<>Daughter of</>) : (<>Putri dari :</>)}</b><br />
                                        {lang=="en"?`Mr. `:`Bpk `}{bapak_ce}  <br />
                                                        &<br />
                                                        {lang=="en"?`Mrs. `:`Ibu `}{ibu_ce}
                                                    </p>
                                                </Item>
                                                <Item>

                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_ce}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                        <div className=' col-xs-12 mt-3 mt-lg-0  col-lg-6' data-aos="fade-right" data-aos-duration="1000">
                                            <div className='kotak mr-lg-2'>
                                                <Item>
                                                    <h1 style={{ fontSize: '72px', fontFamily: "'Marck Script', cursive", color: cmain }}>
                                                        {inisial_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <Col xs={6}>
                                                        <img src={gambar(foto_co, 90)} className='img-fluid w-100' />
                                                    </Col>
                                                </Item>
                                                <Item>
                                                    <h1 className="py-3 w-100 text-center" style={{
                                                        fontSize: '32px',
                                                        fontFamily: "'Marck Script', cursive", color: cmain
                                                    }}>
                                                        {lengkap_co}
                                                    </h1>
                                                </Item>
                                                <Item>
                                                    <p className='text-center' style={{ fontSize: '20px', color: '#rgb(50,49,47)' }}>
                                                        <b>{lang == "en" ? (<>Son of</>) : (<>Putra dari:</>)}</b><br />

                                                        {lang=="en"?`Mr. `:`Bpk `} {bapak_co}
                                                        <br />
                                                        &<br />
                                                        {lang=="en"?`Mrs. `:`Ibu `}{ibu_co}
                                                    </p>
                                                </Item>
                                                <Item>
                                                    <img src={logoig} className='btn p-0'
                                                        onClick={() => { window.open(`https://instagram.com/${ig_co}`) }} width="35px" height="35px" />

                                                </Item>
                                            </div>
                                        </div>
                                    </Item>
                                </Container>
                                <Container fluid className="text-center px-4 dinny" style={{ color: black }} >
                                    <Item>
                                        <p className="fs16">
                                            {lang=="en"?(<>The wedding will be held on :</>):(<>Akan dilaksanakan pada:</>)}
                                </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20">
                                            <b>
                                            {lang=="en"?(<>SATURDAY</>):(<>SABTU</>)} <span className="fs36">02</span> JAN 2021
                      </b>
                                        </p>
                                    </Item>
                                    <Item>
                                        <p className="fs20 col-sm-4" style={{ color: cmain }}>
                                            <b>{lang=="en"?(<>The sacrament <br />of holy matrimony</>):(<>Penerimaan <br />Sakramen Perkawinan</>)}</b><br />
                                            <span className="cblack fs16">

                                            {lang=="en"?(<>9h30 WIB</>):(<>09.30 WIB</>)}
                      </span><br />
                                            <b style={{ fontSize: '16px', color: 'black' }}>
                                            {lang=="en"?(<>St. Paul Catholic Church, Depok:</>):(<>Gereja Katolik St Paulus Depok</>)}

                        <br />
                        Jalan Melati no 4, Depok
                        </b>

                                        </p>
                                        <p className="px-3 d-none d-sm-block" style={{ color: cmain, fontSize: '72px' }}>
                                            \
                    </p>
                                        <div className="col-8 d-sm-none" style={{ borderBottom: `2px solid ${cmain}` }}>
                                        </div>
                                        <p className="fs20 pt-3 pt-sm-0 col-sm-4" style={{ color: cmain }}>
                                            <b>{lang=="en"?(<>The wedding reception <br /> for the family</>):(<>Ramah Tamah <br />untuk Keluarga</>)}</b><br />

                                            <span className="cblack fs16">
                                                
                                                {lang=="en"?(<>11h30 WIB</>):(<>11.30 WIB</>)}
                                               </span><br />
                                            <b style={{ fontSize: '16px', color: 'black' }}>
                                            {lang=="en"?(<>Savero Hotel Depok</>):(<>Hotel Savero Depok, Lily Protea Room
</>)}
                                                
                                                <br />
                                                Jalan Margonda Raya No 230A
                                                </b>

                                        </p>


                                    </Item>


                                    <Item>
                                        <div className="mapouter m-3"><div className="gmap_canvas text-center">
                                            <iframe width="400" height="300" id="gmap_canvas"
                                                src={gmaps1} frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                                            </iframe></div>
                                        </div>
                                    </Item>
                                    <Item>
                                        <Col xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            onClick={() => {
                                                window.open(gmaps)
                                            }}
                                            className="p-2 mx-sm-2 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979874.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Get Direction</b>
                                                </p>
                                            </Item>
                                        </Col>
                                        <Col
                                            onClick={() => window.open(gcalendar)}
                                            xs={10} sm={3}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-sm-2 mt-3 mt-sm-0 btn">
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/979/979863.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Add to Calendar</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                    <Item>
                                        <p style={{ fontSize: '16px', fontStyle: 'italic' }}>
                                            {lang=="en"?(<>Kindly accept our sincerest apologies for the limited number of the invitees due to the COVID 19 pandemic situation. However, we still humbly ask for your continuous prayers for our marriage journey while bearing witness to the moments of our sacred vow before the Lord via live streaming by clicking on the links below.
                                            
                                            </>):(<>Mohon maaf, sehubungan dengan keterbatasan kondisi dalam masa pandemi Covid-19, kami mengundang Bapak/Ibu/Saudara/i berkenan untuk turut memberikan doa restu, serta turut ambil bagian dalam momen penyatuan cinta kami, yang dapat disaksikan melalui siaran live streaming di tautan berikut
                                            
                                            </>)}
                                            </p>
                                    </Item>
                                    <Item>
                                        <Col
                                            onClick={() => window.open("https://youtu.be/Zos-HR7E74U")}
                                            xs={11} md={4}
                                            style={{
                                                border: `2px solid ${cmain}`,
                                                borderRadius: '10px'
                                            }}
                                            className="btn p-2 mx-2 mt-3 mt-md-0">
                                            <Item>
                                                <img src={'https://www.flaticon.com/svg/static/icons/svg/2972/2972381.svg'} className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Live Streaming</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                </Container>
                                <Timer cmain={cmain} waktunikah={waktunikah} />




                                <Container className='mt-3 py-3' data-aos="fade-right" data-aos-duration="1000">
                                    <Slider slide={slider} />
                                </Container>


                                <Container id='sectiongold56'>
                                    <div className='pt-3'>

                                        <div data-aos={`fade-right`} data-aos-duration="2000">
                                            <Item>
                                                <div className='kotak col-10' style={{ backgroundColor: cmain }}>
                                                    <Item>
                                                        <p className='text-center p-2 px-4 fs14'>
                                                            
{lang=="en"?(<>As the beauty of the rainbow radiates through its colours, so too the harmony of our uniqueness syncs in unity like a melodious symphony.
</>):(<>Seperti warna pada pelangi, indah karena warna-warni
<br />Begitu pula harmoni perbedaan dalam pribadi kami,
<br />Layaknya alunan melodi dalam sebuah simfoni</>)}
</p>
                                                    </Item>
                                                </div>

                                            </Item>
                                        </div>
                                    </div>
                                </Container>
                                <Container style={{ border: `2px solid ${cmain}`, borderRadius: '15px' }} className="p-3">
                                    <p style={{ fontFamily: 'Rosemary', color: 'black', fontSize: '16px' }}>
                                       {lang=="en"?(<>We are most grateful for all forms of supports to our marriage. Your sincere prayers mean the world to us, more than any gifts.
If by any chance, you are willing to express a token of supports, you may use one of the options below:
</>):(<>
    Dengan segala kerendahan hati seraya mengucap syukur atas karunia Tuhan, kami sampaikan terima kasih atas doa dan restu untuk kehidupan pernikahan kami.
Menanggapi banyaknya niat baik dan keinginan luhur Bapak/Ibu/Saudara/i dalam mendukung pernikahan kami, berikut informasi lebih jelas mengenai pemberian hadiah pernikahan yang dapat dilihat pada tautan dibawah ini
                                        </>)}
                                    </p>
                                    <Item>
                                        <Col
                                            onClick={() => this.setState({ showgift: !showgift })}
                                            xs={10} md={4}
                                            style={{
                                                border: `2px solid #86835dff`,
                                                borderRadius: '10px'
                                            }}
                                            className="p-2 mx-md-2 mt-3 mt-md-0 btn" >
                                            <Item>
                                                <img src="https://www.flaticon.com/svg/static/icons/svg/1139/1139982.svg" className="img-fluid"
                                                    style={{ width: "10%", height: '10%' }} />
                                                <p className="mb-0 my-auto ml-3" style={{ color: cmain }}>
                                                    <b>Send Gift (click here)</b>
                                                </p>
                                            </Item>
                                        </Col>
                                    </Item>
                                    <p style={{ fontFamily: 'Rosemary', color: 'black', fontSize: '16px' }} className={`py-3 ${showgift ? 'd-block' : 'd-none'}`}>
                                        {lang=="en"?(<>If by any chance, you are willing to express a token of supports, you may use one of the options below:<br/>
                                        </>):(<>Terima kasih atas segala bentuk keterlibatan Bapak/Ibu/Saudara/i dalam mendukung pernikahan kami. Doa dan restu dari Bapak/Ibu/Saudara/i jauh lebih berarti bagi kebahagiaan pernikahan kami.<br />
                                        <br />
                                        Sekiranya terdapat hadiah pernikahan yang hendak diberikan kepada kami, berikut informasi nomor akun yang dapat digunakan:<br /><br />
                                        </>)}
                                        1. BNI 0167728789<br />
                                        2. BCA 7150813268<br />
                                        3. CIMB 760998146100<br />
                                        4. Gopay/OVO 087782313083<br />
                                        <br />
                                        {lang=="en"?(<>Account name: Herlina</>):(<>Akun atas nama Herlina</>)}<br />
                                        <br />
                                        {lang=="en"?(<>In order to facilitate our administrative process, we encourage you to put the number 1 by the end of your decided amount, e.g. IDR 10,001
                                        </>):(<>Mohon tambahkan angka 1 pada digit terakhir nominal pemberian untuk memudahkan kami dalam pendataan.<br />
                                        Contoh: Rp. 10.001,-</>)}
                                        <br /><br />
                                        {lang=="en"?(<>Thank you and may the Lord bless you.</>):(<>  Terima kasih<br />
                                        Tuhan memberkati 😇</>)}
                                    </p>

                                </Container>
                                <div style={{ backgroundColor: '#A30000', position: 'relative' }}>
                                    <Col md={2} xs={5} className="position-absolute" style={{ left: '0px', top: "15px" }}>
                                        <img src={pw(id, "corner1.jpg")} className="mirror img-fluid w-100" />
                                    </Col>
                                    <Col md={2} xs={5} className="position-absolute" style={{ right: '0px', top: "15px" }}>
                                        <img src={pw(id, "corner1.jpg")} className=" img-fluid w-100" />
                                    </Col>
                                    <Col md={2} xs={5} className="position-absolute" style={{ left: '0px', bottom: "15px" }}>
                                        <img src={pw(id, "corner1.jpg")} className=" flip180  img-fluid w-100" />
                                    </Col>
                                    <Col md={2} xs={5} className="position-absolute" style={{ right: '0px', bottom: "15px" }}>
                                        <img src={pw(id, "corner1.jpg")} className=" flip180-m img-fluid w-100" />
                                    </Col>
                                    <Container id='sectiongold58' >

                                        <div className='pt-3 mt-4 mt-lg-5 mb-lg-3'>
                                            <Item>
                                                <Col xs={4} md={5} className="py-3">
                                                    <img data-aos="zoom-in" data-aos-duration="1000"
                                                        src={pw(id, "bunga.png")} className='img-fluid w-100' />
                                                </Col>
                                            </Item>
                                            <Item>
                                                <div className='col-10 col-lg-6 kotak pb-4 pt-4' data-aos="left-left" data-aos-duration="1000">
                                                    <Item>
                                                        <h1 className="w-100 text-center" style={{
                                                            fontFamily: '"Marck Script", cursive',
                                                            color: cmain
                                                        }}>
                                                            Send Your Wishes
                    </h1>
                                                    </Item>
                                                    <Item>
                                                        <form className="col-12 w-100">
                                                            <input ref={this.nama} type='text' className="col-12 w-100 text-center" placeholder="Name" name='nama' />
                                                            <input ref={this.pesan} type='text-area' className="col-12 w-100 text-center bigger" placeholder="Message" name='pesan' />
                                                            {/* <Item>
                                                            <div id="formradio">
                                                                <div class="custom_radio row justify-content-center">
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: true })
                                                                    }
                                                                    }>
                                                                        <input type="radio" id="featured-1" name="featured" checked={hadir ? true : false} />
                                                                        <label for="featured-1">Hadir</label>
                                                                    </div>
                                                                    <div onClick={() => {
                                                                        this.setState({ hadir: false })
                                                                    }
                                                                    } className="pl-5">
                                                                        <input type="radio" id="featured-2" name="featured" checked={hadir ? false : true} />
                                                                        <label for="featured-2"

                                                                        >Tidak Hadir</label>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </Item> */}

                                                            <Item>
                                                                <Col xs={12} className=''>
                                                                    {
                                                                        submitted == true ? (
                                                                            <Alert variant='success' style={{ fontSize: '16px' }}>
                                                                                Pesan anda sudah disampaikan
                                                                            </Alert>) : (submitted === false ? (
                                                                                <Alert variant='danger' style={{ fontSize: '16px' }}>
                                                                                    {
                                                                                        err.map(val => {
                                                                                            return (
                                                                                                <li>{val}</li>
                                                                                            )
                                                                                        })
                                                                                    }

                                                                                </Alert>
                                                                            ) : false)
                                                                    }

                                                                </Col>
                                                            </Item>
                                                            <Item>
                                                                <div className='col-6 button rounded btn'
                                                                    onClick={() => this.handleSubmit()} style={{ backgroundColor: cmain, color: 'white' }} no> Kirim </div>
                                                            </Item>
                                                        </form>
                                                    </Item>
                                                </div>
                                            </Item>
                                        </div>
                                    </Container>

                                    <Foot ig={pw("asset", "logoig-white.svg")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>


        )
    }
}

